import Vue from 'vue'
import {
  Toast,
  Button,
  Field,
  Loading,
  Popup,
  Icon,
  Picker,
  Uploader,
  Overlay,
  DatetimePicker,
  DropdownMenu,
  DropdownItem
} from 'vant'
Vue.use(Toast)
Vue.use(Button)
Vue.use(Field)
Vue.use(Loading)
Vue.use(Popup)
Vue.use(Icon)
Vue.use(Uploader)
Vue.use(Picker)
Vue.use(Overlay)
Vue.use(DatetimePicker)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
