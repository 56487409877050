<template>
  <div class="loading-box" :class="{ background: loadingIconType === 1 }">
    <VanPopup
      :overlay="false"
      :close-on-click-overlay="false"
      style=""
      v-model="show"
      :round="loadingIconType ? true : false"
      :class="{ 'round-box': loadingIconType === 2 }"
      :style="
        loadingIconType === 2
          ? 'background: rgba(0,0,0,0.8)'
          : 'background: transparent'
      "
    >
      <div class="loadingWrap" v-if="loadingIconType === 1">
        <img class="loading-logo" src="../../assets/images/logo.png" />
      </div>
      <div class="loadingWrap" v-else>
        <VanLoading color="#fff" vertical />
      </div>
      <slot></slot>
    </VanPopup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'loading',
  /**
   * @needTimer  // 是否打开倒计时
   */
  props: {
    needTimer: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      show: true,
      timer: null,
      toastLoading: null,
      second: 15
    }
  },
  computed: {
    ...mapState(['loadingIconType']) // 默认0 vant 圈圈 1 logo图 2带有背景色的圈圈
  },
  methods: {
    init () {
      // this.setLoading()
      if (this.needTimer) {
        this.setTimer()
      }
    },
    setTimer () {
      this.timer = setInterval(() => {
        this.second = this.second - 1
        if (!this.second) {
          clearInterval(this.timer)
          this.toastLoading = null
        }
      }, 1000)
    }
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    this.timer = null
  }
}
</script>

<style lang='scss' scoped>
.loading-box {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 22222;
}
.background {
  background: rgba(0, 0, 0, 0.9);
}
.loadingWrap {
  text-align: center;
  .loading-logo {
    width: 100px;
    animation: scale  1000ms linear infinite;
  }
  >>> .van-popup {
    background-color: rgba(255, 255, 255, 0.2);
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  @keyframes scale {
    0% {
      transform: scale(0.85);
    }
    50% {
      transform: scale(1);
    }
     100% {
      transform: scale(0.85);
    }
  }
}
.round-box {
  padding: 20px;
  box-sizing: border-box;
}
</style>
