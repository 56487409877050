export default {
  changeAccount (ctx, myAccount) {
    ctx.commit('SET_MY_ACCOUNT', myAccount)
  },
  changeLoading (ctx, isLoading) {
    ctx.commit('SET_LOADING', isLoading)
  },
  chanegDaoList (ctx, daoList) {
    ctx.commit('SET_DAOLIST', daoList)
  },
  chanegProposalDetail (ctx, proposalDetail) {
    ctx.commit('SET_PROPOSAL_DETAIL', proposalDetail)
  },
  changeLoadingIconType (ctx, type) {
    ctx.commit('SET_LOADING_ICON_TYPE', type)
  },
  changeLang (ctx, lang) {
    ctx.commit('SET_LANG', lang)
  }
}
