import { setUrlPath } from './index'
import router from '../router'
import moment from 'moment'
const handleToPage = (url) => {
  if (url) {
    // 跳转
    router.push(url)
  } else {
    router.replace('/')
  }
}

// 钱包地址格式化
const formatAddress = (value, flag = '...', num = 3) => {
  if (!value) return 0
  const startAddress = value.slice(0, num)
  const endAddress = value.slice(-num)
  const address = startAddress + flag + endAddress
  return address
}

// 货币四位小数
const formatBalance = (value) => {
  value = Number(value.toString().match(/^\d+(?:\.\d{0,4})?/))
  return value
}

// 货币两位小数
const formatTwoBalance = (value) => {
  value = Number(value.toString().match(/^\d+(?:\.\d{0,2})?/))
  return value
}

// 数字转为三位一隔
const formatNumber = (nStr) => {
  var x, x1, x2
  nStr += ''
  x = nStr.split('.')
  x1 = x[0]
  x2 = x.length > 1 ? '.' + x[1] : ''
  var rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}

// 都转为小写
const toUpperCase = (value) => {
  return value.toUpperCase()
}

// 都转为小写
const toLowerCase = (value) => {
  return value.toLowerCase()
}
const showStartime = (time, lang = 'EN') => {
  // console.log('time', time)
  // console.log('new Date()', new Date())
  const start = moment(new Date()) // 获取开始时间
  // console.log(start)
  const end = moment(time * 1000) // 结束时间
  // console.log(end)
  const diff = end.diff(start) // 时间差
  // console.log('diff', diff)

  var timediff = Math.round(diff / 1000)
  // 获取还剩多少天
  var day = parseInt(timediff / 3600 / 24)
  // 获取还剩多少小时
  var hour = parseInt((timediff / 3600) % 24)
  // 获取还剩多少分钟
  var minute = parseInt((timediff / 60) % 60)
  // 获取还剩多少秒
  // var second = timediff % 60
  // // 输出还剩多少时间
  // console.log(
  //   day +
  //     '天' +
  //     timerFilter(hour) +
  //     '时' +
  //     timerFilter(minute) +
  //     '分' +
  //     timerFilter(second) +
  //     '秒'
  // )
  const dayText = lang === 'EN' ? ' days' : '天'
  const dayObj = day > 0 ? day + dayText : ''
  const hourText = lang === 'EN' ? ' hours' : '时'
  const hourObj = hour > 0 ? timerFilter(hour) + hourText : ''
  const minuteText = lang === 'EN' ? ' minutes' : '分'
  const minuteObj = minute > 0 ? timerFilter(minute) + minuteText : ''
  return (
    dayObj + hourObj + minuteObj
    //  +
    // timerFilter(second) +
    // '秒'
  )
}

const showEndTime = (time, lang = 'EN') => {
  // console.log('time', time)
  // console.log('new Date()', new Date())
  const start = moment(new Date()) // 获取开始时间
  // console.log(start)
  const end = moment(time * 1000) // 结束时间
  // console.log(end)
  const diff = end.diff(start) // 时间差
  var timediff = Math.round(diff / 1000)
  // 获取还剩多少天
  var day = parseInt(timediff / 3600 / 24)
  // 获取还剩多少小时
  var hour = parseInt((timediff / 3600) % 24)
  // 获取还剩多少分钟
  var minute = parseInt((timediff / 60) % 60)
  // 获取还剩多少秒
  // var second = timediff % 60
  // 输出还剩多少时间
  // console.log(
  //   day +
  //     '天' +
  //     timerFilter(hour) +
  //     '时' +
  //     timerFilter(minute) +
  //     '分' +
  //     timerFilter(second) +
  //     '秒'
  // )
  const dayText = lang === 'EN' ? ' days' : '天'
  const dayObj = day > 0 ? day + dayText : ''
  const hourText = lang === 'EN' ? ' hours' : '时'
  const hourObj = hour > 0 ? timerFilter(hour) + hourText : ''
  const minuteText = lang === 'EN' ? ' minutes' : '分'
  const minuteObj = minute > 0 ? timerFilter(minute) + minuteText : ''
  return (
    dayObj + hourObj + minuteObj
    //  +
    // timerFilter(second) +
    // '秒'
  )
}
// 给小于10的数值前面加 0
function timerFilter (params) {
  if (params - 0 < 10) {
    return '0' + params
  } else {
    return params
  }
}
function objHeavy (arr) {
  var newArr = [] // 存新数组
  var obj = {} // 存处理后转成字符串的对象
  for (var i = 0; i < arr.length; i++) {
    var keys = Object.keys(arr[i])
    keys.sort(function (a, b) {
      return Number(a) - Number(b)
    })
    var str = ''
    for (var j = 0; j < keys.length; j++) {
      str += JSON.stringify(keys[j])
      str += JSON.stringify(arr[i][keys[j]])
    }
    if (!Object.prototype.hasOwnProperty.call(obj, str)) {
      newArr.push(arr[i])
      obj[str] = true
    }
  }
  return newArr
}

const handleRound = (num, iCount = 2) => {
  // iCount 保留几位小数
  let changeNum = num
  let zs = true
  // 判断是否是负数
  if (changeNum < 0) {
    changeNum = Math.abs(changeNum)
    zs = false
  }
  const iB = Math.pow(10, iCount)
  // 有时乘100结果也不精确
  const value1 = changeNum * iB
  let intDecSet = []
  let intDecHun = []

  let fValue = value1
  const value2 = value1.toString()
  const iDot = value2.indexOf('.')

  // 如果是小数
  if (iDot !== -1) {
    intDecSet = changeNum.toString().split('.')

    // 如果是科学计数法结果
    if (intDecSet[1].indexOf('e') !== -1) {
      return Math.round(value1) / iB
    }
    intDecHun = value2.split('.')

    if (intDecSet[1].length <= iCount) {
      return parseFloat(num, 10)
    }

    const fValue3 = parseInt(intDecSet[1].substring(iCount, iCount + 1), 10)

    if (fValue3 >= 5) {
      fValue = parseInt(intDecHun[0], 10) + 1
    } else {
      // 对于传入的形如111.834999999998 的处理（传入的计算结果就是错误的，应为111.835）
      if (
        fValue3 === 4 &&
        intDecSet[1].length > 10 &&
        parseInt(intDecSet[1].substring(iCount + 1, iCount + 2), 10) === 9
      ) {
        fValue = parseInt(intDecHun[0], 10) + 1
      } else {
        fValue = parseInt(intDecHun[0], 10)
      }
    }
  }
  // 如果是负数就用0减四舍五入的绝对值
  let val = zs ? fValue / iB : 0 - fValue / iB

  const d = val.toString().split('.')
  if (d.length === 1) {
    return val.toString() + '.00'
  }
  if (d.length > 1) {
    if (d[1].length < 2) {
      val = val.toString() + '0'
    }
    return val
  }
}
 /**
  * 精准乘法
  * @param {Object} arg1
  * @param {Object} arg2
  */
const accMul = (arg1, arg2) => {
  var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
  try {
      m += s1.split(".")[1].length;
  }
  catch (e) {
  }
  try {
      m += s2.split(".")[1].length;
  }
  catch (e) {
  }
  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}

const gbUtils = {
  objHeavy,
  showStartime,
  showEndTime,
  setUrlPath,
  handleToPage,
  formatBalance,
  formatTwoBalance,
  formatAddress,
  formatNumber,
  toUpperCase,
  toLowerCase,
  handleRound,
  accMul
}

export default gbUtils
