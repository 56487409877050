import Vue from 'vue'
import VueRouter from 'vue-router'

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    meta: {},
    component: () => import('../views/login/Login.vue')
  },
  {
    path: '/systerm',
    name: 'Systerm',
    meta: {},
    component: () => import('../views/systerm/Systerm.vue')
  },
  {
    path: '/dao',
    name: 'Dao',
    meta: {
      selected: 1
    },
    component: () => import('../views/dao/Dao.vue')
  },
  {
    path: '/createDao',
    name: 'CreateDao',
    meta: {},
    component: () => import('../views/dao/CreateDao.vue')
  },
  {
    path: '/modifyDao',
    name: 'ModifyDao',
    meta: {},
    component: () => import('../views/dao/ModifyDao.vue')
  },
  {
    path: '/detail',
    name: 'Detail',
    meta: {
      selected: 2
    },
    component: () => import('../views/detail/Detail.vue')
  },
  {
    path: '/applyProposal',
    name: 'ApplyProposal',
    meta: {},
    component: () => import('../views/proposal/ApplyProposal.vue')
  },
  {
    path: '/proposalDetail',
    name: 'ProposalDetail',
    meta: {
      selected: 0
    },
    component: () => import('../views/detail/ProposalDetail.vue')
  },
  {
    path: '/my',
    name: 'My',
    meta: {
      selected: 3
    },
    component: () => import('../views/my/My.vue')
  },
  {
    path: '/modifyProposal',
    name: 'ModifyProposal',
    meta: {},
    component: () => import('../views/proposal/ModifyProposal.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
