import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

let myAccount, isLoading, loadingIconType, lang, proposalDetail, daoList

try {
  isLoading = false
} catch {
  isLoading = false
}

try {
  myAccount = localStorage.getItem('myAccount')
} catch {
  localStorage.setItem('myAccount', myAccount)
}

try {
  lang = localStorage.getItem('lang')
} catch {
  localStorage.setItem('lang', 'EN')
  lang = 'EN'
}

try {
  daoList = JSON.parse(localStorage.getItem('daoList'))
} catch {
  localStorage.setItem('daoList', JSON.stringify(daoList))
}

try {
  proposalDetail = JSON.parse(localStorage.getItem('proposalDetail'))
} catch {
  localStorage.setItem('proposalDetail', JSON.stringify(proposalDetail))
}

export default {
  myAccount, // 钱包账户地址
  isLoading,
  loadingIconType,
  lang,
  proposalDetail,
  daoList
}
