import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import _ from 'lodash'
import moment from 'moment'
import i18n from './i18n'
import web3 from './utils/web3'
import './plugin/vant'
import gbUtils from './utils/gbUtils'

export default i18n

Vue.prototype.$_ = _
Vue.prototype.$moment = moment
Vue.prototype.$web3 = web3
Vue.prototype.$gbUtils = gbUtils

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
