import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store/index'
import EN from './EN.json'
import ZH from './ZH.json'
Vue.use(VueI18n)
var lang = store.state.lang
if (!lang) {
  store.commit('SET_LANG', 'EN')
  lang = 'EN'
}

const i18n = new VueI18n({
  locale: lang,
  silentTranslationWarn: true,
  messages: {
    EN: EN,
    ZH: ZH
  }
})
export default i18n
