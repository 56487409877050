<template>
  <div id="app">
    <router-view
      :class="{ 'tab-page': $route.meta.selected }"
      @changeLoading="changeLoading"
      @changeLoadingIconType="changeLoadingIconType"
      v-if="isRouterAlive"
    ></router-view>
    <BottomTabBar
      :path="$route.path"
      :selected="$route.meta.selected"
      v-if="$route.meta.selected"
    />
    <!-- 加载loading -->
    <Loading v-if="isLoading">
      <div class="loading-text" v-if="loadinMessage"></div>
    </Loading>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loading from '@/components/common/LoadingPop.vue'
import BottomTabBar from './components/common/BottomTabBar.vue'
export default {
  components: { Loading, BottomTabBar },
  data () {
    return {
      loadinMessage: '', // 加载提示信息
      isRouterAlive: true
    }
  },
  provide () {
    return {
      reload: this.reload
    }
  },
  computed: {
    ...mapState(['isLoading', 'myAccount'])
  },
  methods: {
    ...mapActions(['changeAccount', 'changeLoading', 'changeLoadingIconType']),
    init () {
      // this.getAmount()
      if (!this.myAccount) {
        this.$router.replace('/login')
      }
      this.listenWallet()
    },
    // 刷新
    reload () {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
    // 钱包监听
    listenWallet () {
      // 账号切换
      this.$web3.ethereum.on('accountsChanged', (accounts) => {
        console.log('accountsChanged')
        // this.$gbUtils.handleToPage('/home')
        this.signFlag = false
        this.routerViewShow = false
        this.changeAccount('')
      })
      // 断开链接
      this.$web3.ethereum.on('disconnect', (res) => {
        console.log('disconnect')
        this.signFlag = false
        this.routerViewShow = false
        this.changeAccount('')
      })
    },
    // 获取判断用户登录
    async getAmount () {
      if (this.myAccount) return
      const resp = await this.$web3.connectWallet()
      if (!resp.success) return
      const account = resp.result
      this.changeAccount(account)
    }
  },
  created () {
    this.init()
  },
  watch: {
    myAccount (val) {
      this.reload()
      this.$router.replace('/login')
      // if (!val) {
      //   this.$router.replace('/login')
      // }
    }
  }
}
</script>

<style lang="scss">
html {
  background: $base-bg;
  color: $base-color;
  font-size: 12px;
}
#app {
  height: 100%;
  overflow: scroll;
  position: relative;
  font-size: 12px;
  .input-box {
    padding: 0 5px;
    height: 40px;
    line-height: 40px;
    box-shadow: $shadow;
    border-radius: 8px;
    background: $main-bg;
    color: $base-color;
    * {
      -webkit-overflow-scrolling: touch;
    }
    ::-webkit-input-placeholder {
      color: $color-gray;
      font-size: $font-12;
    }
    /deep/.van-field__body {
      height: -webkit-fill-available;
    }
    input {
      color: $color-gray3 !important;
    }
  }
  .textarea-box {
    background: $main-bg;
    ::-webkit-input-placeholder {
      color: $color-gray;
      font-size: $font-12;
    }
    textarea {
      color: $color-gray3 !important;
    }
  }
}
.van-button {
  border: none !important;
}
.bg-wrapper {
  background: url('../src/assets/images/bg.png') no-repeat;
  background-size: 100% auto;
}
@media screen and(min-device-width: 1024px) {
  html {
    transform-origin: 50% 2%;
    overflow: hidden;
    background: unset !important;
    padding: 20px 0;
    // font-variant-east-asian: traditional;
    -moz-user-select: none; /*火狐*/
    -webkit-user-select: none; /*webkit浏览器*/
    -ms-user-select: none; /*IE10*/
    -khtml-user-select: none; /*早期浏览器*/
    user-select: none;
  }
  #app {
    height: 800px;
    overflow-y: scroll;
    border-radius: 10px;
    width: 375px;
    margin: auto;
    background: $base-bg;
    position: relative;
  }
  .tab-bar {
    width: 375px;
    left: 50% !important;
    transform: translate(-50%, -20px);
    // bottom: 20px;
  }
}
.tab-page {
  padding-bottom: 200px !important;
}
</style>
