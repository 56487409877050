import i18n from '../main.js'
export default {
  SET_MY_ACCOUNT (state, myAccount) {
    state.myAccount = myAccount
    if (myAccount) {
      localStorage.setItem('myAccount', myAccount)
    } else {
      localStorage.removeItem('myAccount')
    }
  },
  SET_DAOLIST (state, daoList) {
    state.daoList = daoList
    if (daoList) {
      localStorage.setItem('daoList', JSON.stringify(daoList))
    } else {
      localStorage.removeItem('daoList')
    }
  },
  SET_PROPOSAL_DETAIL (state, proposalDetail) {
    state.proposalDetail = proposalDetail
    if (proposalDetail) {
      localStorage.setItem('proposalDetail', JSON.stringify(proposalDetail))
    } else {
      localStorage.removeItem('proposalDetail')
    }
  },
  SET_LOADING (state, isLoading) {
    state.isLoading = isLoading
  },
  SET_LOADING_ICON_TYPE (state, loadingIconType) {
    state.loadingIconType = loadingIconType
  },
  SET_LANG (state, lang) {
    state.lang = lang
    if (i18n) {
      i18n.locale = lang // i18n语言切换
    }
    if (lang) {
      localStorage.setItem('lang', lang)
    } else {
      localStorage.removeItem('lang')
    }
  }
}
