<template>
  <div class="tab-bar">
    <div class="tab-group">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="tab-bar-item"
        :class="{ active: path === item.url || selected === index + 1 }"
        @click="onChange(item, index)"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    selected: {
      default () {
        return 0
      }
    },
    path: {
      default () {
        return 0
      }
    }
  },
  data () {
    return {
      urls: ['/dao', '/detail', '/my']
    }
  },
  computed: {
    list () {
      return this.$t('tabList')
    }
  },
  methods: {
    onChange (item, index) {
      this.$gbUtils.handleToPage(this.urls[index])
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.tab-bar {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 999;
  border-radius: 10px 10px 0 0;
  background: $base-bg;
}

.tab-group {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  justify-content: space-around;
}

.tab-bar-item {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 110px;
  height: 39px;
  background: $base-bg;
  color: $main-color1;
  border-radius: 20px;
  font-size: $font-14;
  font-weight: normal;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
     z-index: -1;
     margin: -1px;
     border-radius: inherit; /*important*/
     background: linear-gradient(to right, #0078FF, #7C40B7);
  }
}

.active {
  background: $blue-bg;
  color: $base-color;
  font-size: $font-16;
  font-weight: bold;
  &::before {
    background: none;
  }
}
</style>
